<template>
    <div
      v-if="
        ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.tesouraria == 'S') &&
          $store.state.fastPermissoes.ativo == 'S' &&
          $store.state.fastPlataformaModulos.modulo_secretaria
      "
    >
      <div class="fd-app-wrap">
        <div class="container">
          <!-- breadcrumb -->
          <nav>
    
            <ul class="fd-app-breadcrumb">
              <li class="fd-app-breadcrumb-item">
                <a
                  href="/minhas-plataformas"
                  class="aluno_font_color"
                  @click.prevent="$router.push('/minhas-plataformas')"
                >Minhas Plataformas</a>
              </li>
              <li class="fd-app-breadcrumb-item">
                <a
                  class="aluno_font_color"
                  href="#"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
                >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
              </li>
              <li class="fd-app-breadcrumb-item">
                <a
                  class="aluno_font_color"
                  href="#"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
                >Modo tesouraria</a>              
              </li>
              <li class="fd-app-breadcrumb-item active">
                <a
                  class="aluno_font_color"
                  href="#"
                >Relatórios - Matrículas</a>
              </li>
            </ul>
          </nav>
  
          <!-- /breadcrumb -->
        </div>
        <div class="fd-mode-adm-content mt-5 container">
          
          <!-- Conteúdo Principal Relatório -->
          <transition name="slide-fade">
            <div v-if="fastNavegacao.exibirRelatorio">
              <section class="fd-app-welcome text-left">
                  <h2><b>Relatório Matrículas</b></h2>
                  <img
                    class="mb-3"
                    :src="require('@/assets/images/form-effect-01.png')"
                    style="max-height: 16px"
                  >
                </section>
                <div class="d-flex">
                  <div class="align-self-start mr-auto mb-2">
                    <button v-if="this.fastMatriculasRel.filtroEscolhido.idInvoice"
                    @click="fastMatriculasRel.filtroEscolhido.idInvoice = '', getMatriculasRel()" type="button"
                    class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                    Invoice: {{ this.fastMatriculasRel.filtroEscolhido.idInvoice }} <b-icon icon="x-lg" font-scale="0.7"
                      class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastMatriculasRel.filtroEscolhido.idMatricula"
                      @click="fastMatriculasRel.filtroEscolhido.idMatricula = '', getMatriculasRel()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Matrícula: {{ this.fastMatriculasRel.filtroEscolhido.idMatricula }} <b-icon icon="x-lg" font-scale="0.7"
                        class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastMatriculasRel.filtroEscolhido.idTurma"
                      @click="fastMatriculasRel.filtroEscolhido.idTurma = '', getMatriculasRel()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Turma: <template 
                              v-for="turma in fastMatriculasRel.filtros.turmas"
                              :value="turma.id_turma">
                              <span v-if="fastMatriculasRel.filtroEscolhido.idTurma == turma.id_turma">{{ turma.descricao_turma }} </span>
                            </template>
                        <b-icon icon="x-lg" font-scale="0.7"
                        class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastMatriculasRel.filtroEscolhido.idMatriculador "
                      @click="fastMatriculasRel.filtroEscolhido.idMatriculador = '', fastMatriculasRel.filtros.usuarioGerou = '', getMatriculasRel()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Matriculado por: {{ this.fastMatriculasRel.filtros.usuarioGerou }} <b-icon icon="x-lg" font-scale="0.7"
                        class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastMatriculasRel.filtroEscolhido.nomeAluno"
                      @click="fastMatriculasRel.filtroEscolhido.nomeAluno = '', getMatriculasRel()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Aluno: {{ this.fastMatriculasRel.filtroEscolhido.nomeAluno }} <b-icon icon="x-lg" font-scale="0.7"
                        class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastMatriculasRel.filtroEscolhido.nomeResponsavel"
                      @click="fastMatriculasRel.filtroEscolhido.nomeResponsavel = '', getMatriculasRel()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Resp.: {{ this.fastMatriculasRel.filtroEscolhido.nomeResponsavel }} <b-icon icon="x-lg"
                        font-scale="0.7" class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastMatriculasRel.filtroEscolhido.matriculaPaga"
                      @click="fastMatriculasRel.filtros.status = '', alteraFiltroStatus(), getMatriculasRel()"
                      type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      <span>Status: Matrícula Paga </span> 
                      <b-icon icon="x-lg" font-scale="0.7" class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastMatriculasRel.filtroEscolhido.matriculaCancelada"
                      @click="fastMatriculasRel.filtros.status = '', alteraFiltroStatus(), getMatriculasRel()"
                      type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      <span>Status: Matrícula Cancelada </span> 
                      <b-icon icon="x-lg" font-scale="0.7" class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastMatriculasRel.filtroEscolhido.matriculaPendente"
                      @click="fastMatriculasRel.filtros.status = '', alteraFiltroStatus(), getMatriculasRel()"
                      type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      <span>Status: Matrícula Pendente </span> 
                      <b-icon icon="x-lg" font-scale="0.7" class="mb-1"></b-icon>
                    </button>
                  </div>
                </div>
              <section>
                <div class="row">
                  <form class="form col-sm-12 col-md-12 col-lg-4 pl-3 pr-3">
                    <label class="text-secondary mb-0 mt-2"><small>Plataforma:</small></label>
                    <select
                      class="form-control form-control-sm"
                      v-model="fastMatriculasRel.filtroEscolhido.idPlataforma"
                      @change="getTurmas()">
                      <option>Todas</option>
                      <option v-for="(plataforma, index) in fastMatriculasRel.filtros.plataformas" :key="index"
                        :value="plataforma.id_plataforma">
                        {{ plataforma.nome_plataforma }}
                      </option>
                    </select>
                    <label class="text-secondary mb-0 mt-2"><small>Invoice:</small></label>
                    <input
                    type="text"
                    v-model="fastMatriculasRel.filtroEscolhido.idInvoice"
                    class="form-control form-control-sm"
                    />
                    <label class="text-secondary mb-0 mt-2"><small>Status Matrícula:</small></label>
                    <select
                    :class="fastMatriculasRel.filtros.status ? 'form-control form-control-sm border border-primary text-primary' : 'form-control form-control-sm'"
                    v-model="fastMatriculasRel.filtros.status" @change="alteraFiltroStatus()">
                    <option value="">Todos</option>
                    <option value="P">
                      Matrícula Paga
                    </option>
                    <option value="A">
                      Matrícula Pendente
                    </option>
                    <option value="C">
                      Matrícula Cancelada
                    </option>
                  </select>
                  </form>
                  <form class="form col-sm-12 col-md-12 col-lg-4 pl-3 pr-4">
                    <label class="text-secondary mb-0 mt-2"><small>Aluno:</small></label>
                    <input
                    type="text"
                    v-model="fastMatriculasRel.filtroEscolhido.nomeAluno"
                    class="form-control form-control-sm"
                    />
                    <label class="text-secondary mb-0 mt-2"><small>Responsável:</small></label>
                    <input
                    type="text"
                    v-model="fastMatriculasRel.filtroEscolhido.nomeResponsavel"
                    class="form-control form-control-sm"
                    />
                    <label class="text-secondary mb-0 mt-2"><small>Turma:</small></label>
                    <select
                      :class="fastMatriculasRel.filtroEscolhido.idTurma ? 'form-control form-control-sm border border-primary text-primary' : 'form-control form-control-sm'"
                      v-model="fastMatriculasRel.filtroEscolhido.idTurma" >
                      <option value ="">Todas</option>
                      <option v-for="(turma, index) in fastMatriculasRel.filtros.turmas" :key="index"
                        :value="turma.id_turma">
                        {{ turma.descricao_turma }}
                      </option>
                    </select>

                  </form>
                  <form class="form col-sm-12 col-md-12 col-lg-4 pl-3 pr-3">
                    <label class="text-primary mb-1 mt-2 row">
                      <small class="text-primary ml-0 pl-3 col-8">Data Matrícula:</small>
                      <small
                      class="col-4 text-right mr-0 pr-3"
                      role="button"
                      v-if="fastMatriculasRel.filtroEscolhido.dataMatriculaStart || fastMatriculasRel.filtroEscolhido.dataMatriculaEnd" 
                      @click="fastMatriculasRel.filtroEscolhido.dataMatriculaStart = '', fastMatriculasRel.filtroEscolhido.dataMatriculaEnd = ''"
                      >Limpar <b-icon icon="x-circle" scale="1.1" variant="danger"></b-icon></small>
                    </label>
                    <div class="row pl-3 pr-3">
                      <b-form-datepicker 
                      :class="validaDataFiltro(fastMatriculasRel.filtroEscolhido.dataMatriculaStart, fastMatriculasRel.filtroEscolhido.dataMatriculaEnd) ? 'col-6 date-picker-filtro' : 'col-6 date-picker-filtro border-danger text-red'"
                      @input="validaDataFiltro(fastMatriculasRel.filtroEscolhido.dataMatriculaStart, fastMatriculasRel.filtroEscolhido.dataMatriculaEnd, true)"
                      v-model="fastMatriculasRel.filtroEscolhido.dataMatriculaStart"
                      locale="pt-BR"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                      label-no-date-selected="Selecionar data inicial"
                      size="sm"
                      placeholder="Selecionar data inicial"
                      label-help="Use o cursor para selecionar"
                      ></b-form-datepicker>
                      <b-form-datepicker 
                      :class="validaDataFiltro(fastMatriculasRel.filtroEscolhido.dataMatriculaStart, fastMatriculasRel.filtroEscolhido.dataMatriculaEnd) ? 'col-6 date-picker-filtro' : 'col-6 date-picker-filtro border-danger text-red'"
                      @input="validaDataFiltro(fastMatriculasRel.filtroEscolhido.dataMatriculaStart, fastMatriculasRel.filtroEscolhido.dataMatriculaEnd, true)"
                      v-model="fastMatriculasRel.filtroEscolhido.dataMatriculaEnd"
                      locale="pt-BR"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                      label-no-date-selected="Selecionar data final"
                      size="sm"
                      placeholder="Selecionar data final"
                      label-help="Use o cursor para selecionar"
                      ></b-form-datepicker>
                    </div>
                    <label class="text-secondary mb-0 mt-2"><small>Matriculador:</small></label>
                    <div style="position: relative">
                      <input 
                        :class="fastMatriculasRel.filtros.usuarioGerou.length ? 'form-control form-control-sm border border-primary text-primary' : 'form-control form-control-sm'"
                        v-model="fastMatriculasRel.filtros.usuarioGerou" 
                        type="text" 
                        :list="fastMatriculasRel.filtros.datalistId"
                        @input="filtraPessoaSugestao('usuarioGerou', 'usuariosGerou')"
                        @keyup.esc="selecionaPessoaSugerida('')"
                      >
                      <ul v-if="fastMatriculasRel.filtros.usuariosGerou.length" class="sugestao-lista">
                        <li 
                        class="sugestao-item"
                        v-for="usuario in fastMatriculasRel.filtros.usuariosGerou" 
                        :key="usuario.id_usuario" 
                        @click="selecionaPessoaSugerida(usuario)">
                          {{ usuario.nome_razao }}
                        </li>
                      </ul>
                    </div>
                    <label class="text-secondary mb-0 mt-2"><small>Matrícula:</small></label>
                    <input
                    type="text"
                    v-model="fastMatriculasRel.filtroEscolhido.idMatricula"
                    class="form-control form-control-sm"
                    />
                  </form>
                </div>
                <div class="row">
                  <div class="text-left col-6 mt-4 mb-3">
                    <b-button @click="exportaPlanilha()" variant="custom-outline-primary btn-sm text-capitalize rounded">Exportar</b-button>                       
                  </div>
                  <div class="text-right col-6 mt-4 mb-3">
                    <b-button @click="limpaFiltros()" variant="custom-outline-primary btn-sm text-capitalize rounded mr-3">Limpar Filtros</b-button>
                    <b-button @click="getMatriculasRel()" variant="custom-primary btn-sm text-capitalize rounded">Filtrar</b-button>                       
                  </div>
                </div>
              </section>

                <div class="row">
                  <div class="col-12 table-responsive">
                    <!--tabela oculta sem paginação para exportar-->
                    <table id="relatorio" class="table d-none">
                      <thead>
                        <tr>
                          <th>
                            <small>Data Matrícula</small>
                          </th>
                          <th>
                            <small>Turma</small>
                          </th>
                          <th>
                            <small>Matrícula</small>
                          </th>
                          <th>
                            <small>Invoice</small>
                          </th>
                          <th>
                            <small>Aluno</small>
                          </th>
                          <th>
                            <small>Inicio Estudo</small>
                          </th>
                          <th>
                            <small>Valor Matrícula</small>
                          </th>
                          <th>
                            <small>Mensalidade</small>
                          </th>
                          <th>
                            <small>Data Previsão Pag. Mensalidade</small>
                          </th>
                          <th>
                            <small>Data Pagamento</small>
                          </th>
                          <th>
                            <small>Status Matrícula</small>
                          </th>
                          <th>
                            <small>Matriculador</small>
                          </th>
                          <th>
                            <small>Log</small>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(e, index) in fastMatriculasRel.filtro"
                          :key="index"
                        >
                          <td>
                            <small>{{ formataDataT(e.data_matricula) }}</small>                                  
                          </td>
                          <td>
                              <small>
                              {{ e.sigla_turma }}
                            </small>                               
                          </td>
                          <td>
                              <small>
                              {{ e.id_matricula }}
                            </small>                               
                          </td>
                          <td>
                              <small>
                              {{ e.id_invoice }}
                            </small>                               
                          </td>
                          <td>
                            <small>
                              {{ e.nome_aluno }}
                            </small>                                 
                          </td>
                          <td>
                            <small>{{ formataDataT(e.data_matricula) }}</small>                                  
                          </td>
                          <td>
                            <small>R$ {{ formataPreco(e.valor_matricula) }}</small>                                  
                          </td>
                          <td>
                            <small>R$ {{ formataPreco(e.valor_parcela) }}</small>                                  
                          </td>
                          <td>
                            <small>{{ formataDataT(e.data_vencimento_mensalidade) }}</small>                                  
                          </td>
                          <td>
                            <small>{{ formataDataT(e.data_pagamento) }}</small>                                  
                          </td>
                          <td>
                            <small v-if="e.matricula_ativa">Ativa</small>    
                            <small v-else>Inativa</small>                           
                          </td>
                          <td>
                              <small>
                              {{ e.UsuarioFezaBaixa }}
                            </small>                               
                          </td>
                          <td>
                            <small v-if="e.log_pagto_auditoria">{{ e.log_pagto_auditoria }}</small>
                            <small v-else>-</small>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table class="table table-sm text-nowrap table-hover">
                      <thead class="thead-dark" v-if="fastMatriculasRel.loading">
                        <tr>
                          <th
                            colspan="12"
                            class="text-center"
                          >
                          <b-icon icon="gear-fill" animation="spin"/> Carregando 
                          </th>
                        </tr>
                      </thead>
                      <thead class="thead-dark" v-if="!fastMatriculasRel.loading">
                        <tr>
                          <th class="pl-3 pt-2">
                            <b-form-checkbox v-model="fastMatriculasRel.selectAll"/>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastMatriculasRel.orderBy.data_matricula !== 0 }" @click="orderByColumn('data_matricula')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                              Data Matrícula&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastMatriculasRel.orderBy.data_matricula === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastMatriculasRel.orderBy.data_matricula === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastMatriculasRel.orderBy.turma !== 0 }" @click="orderByColumn('turma')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                              Turma&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastMatriculasRel.orderBy.turma === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastMatriculasRel.orderBy.turma === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastMatriculasRel.orderBy.matricula !== 0 }" @click="orderByColumn('matricula')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                              Matrícula&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastMatriculasRel.orderBy.matricula === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastMatriculasRel.orderBy.matricula === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastMatriculasRel.orderBy.invoice !== 0 }" @click="orderByColumn('invoice')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                              Invoice&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastMatriculasRel.orderBy.invoice === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastMatriculasRel.orderBy.invoice === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastMatriculasRel.orderBy.aluno !== 0 }" @click="orderByColumn('aluno')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Aluno&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastMatriculasRel.orderBy.aluno === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastMatriculasRel.orderBy.aluno === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastMatriculasRel.orderBy.inicio_estudos !== 0 }" @click="orderByColumn('inicio_estudos')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                              Início Estudo&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastMatriculasRel.orderBy.inicio_estudos === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastMatriculasRel.orderBy.inicio_estudos === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastMatriculasRel.orderBy.valor_matricula !== 0 }" @click="orderByColumn('valor_matricula')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Valor Matrícula&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastMatriculasRel.orderBy.valor_matricula === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastMatriculasRel.orderBy.valor_matricula === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastMatriculasRel.orderBy.mensalidade !== 0 }" @click="orderByColumn('mensalidade')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Mensalidade&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastMatriculasRel.orderBy.mensalidade === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastMatriculasRel.orderBy.mensalidade === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastMatriculasRel.orderBy.data_vencimento_mensalidade !== 0 }" @click="orderByColumn('data_vencimento_mensalidade')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Data Previsão Pag. Mensalidade.&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastMatriculasRel.orderBy.data_vencimento_mensalidade === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastMatriculasRel.orderBy.data_vencimento_mensalidade === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastMatriculasRel.orderBy.data_pagamento !== 0 }" @click="orderByColumn('data_pagamento')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Data Pagto.&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastMatriculasRel.orderBy.data_pagamento === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastMatriculasRel.orderBy.data_pagamento === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastMatriculasRel.orderBy.status !== 0 }" @click="orderByColumn('status')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Status Matrícula&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastMatriculasRel.orderBy.status === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastMatriculasRel.orderBy.status === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastMatriculasRel.orderBy.matriculador !== 0 }" @click="orderByColumn('matriculador')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                              Matriculador&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastMatriculasRel.orderBy.matriculador === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastMatriculasRel.orderBy.matriculador === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small class="font-weight-bold d-flex pb-1 justify-content-center">
                              Log&nbsp;
                              <div class="d-flex flex-column"></div>
                            </small>
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="fastMatriculasRel.loading">
                        <tr>
                          <td
                            colspan="12"
                            class="text-center"
                          >
                          <b-icon icon="gear-fill" animation="spin"/> Carregando 
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else-if="fastMatriculasRel.paginacao.length">
                        <tr
                          v-for="(e, index) in fastMatriculasRel.paginacao"
                          :key="index"
                        >
                          <td class="pl-3 pt-2"
                            v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                          >
                            <b-form-checkbox role="button" type="checkbox" v-model="fastMatriculasRel.selectedItems" :value="e.id_invoice + '-' + e.parcela"/>
                          </td>
                          <td class="align-middle text-center"
                            v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                            role="button"
                            @click="exibeDetalhes(e)"
                          >
                            <small>{{ formataDataT(e.data_matricula) }}</small>                                  
                          </td>
                          <td class="align-middle text-center"
                            v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                            role="button"
                            @click="exibeDetalhes(e)"  
                          >
                              <small>
                              {{ e.sigla_turma }}
                            </small>                               
                          </td>
                          <td class="align-middle text-center"
                            v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                            role="button"
                            @click="exibeDetalhes(e)"  
                          >
                              <small>
                              {{ e.id_matricula }}
                            </small>                               
                          </td>
                          <td class="align-middle text-center"
                            v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                            role="button"
                            @click="exibeDetalhes(e)"  
                          >
                              <small>
                              {{ e.id_invoice }}
                            </small>                               
                          </td>
                          <td class="align-middle text-center"
                            v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                            role="button"
                            @click="exibeDetalhes(e)"
                          >
                            <small>{{ e.nome_aluno }}</small>                                 
                          </td>
                          <td class="align-middle text-center"
                            v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                            role="button"
                            @click="exibeDetalhes(e)"
                          >
                            <small>{{ formataDataT(e.data_iniciou_estudos) }}</small>                                  
                          </td>
                          <td class="align-middle text-center"
                            v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                            role="button"
                            @click="exibeDetalhes(e)"
                          >
                            <small>R$ {{ formataPreco(e.valor_matricula) }}</small>                                  
                          </td>
                          <td class="align-middle text-center"
                            v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                            role="button"
                            @click="exibeDetalhes(e)"
                          >
                            <small>R$ {{ formataPreco(e.valor_parcela) }}</small>                                  
                          </td>                     
                          <td class="align-middle text-center"
                            v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                            role="button"
                            @click="exibeDetalhes(e)"
                          >
                            <small>{{ formataDataT(e.data_vencimento_mensalidade) }}</small>                                  
                          </td>
                          <td class="align-middle text-center"
                            v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                            role="button"
                            @click="exibeDetalhes(e)"
                          >
                            <small>{{ formataDataT(e.data_pagamento) }}</small>                                  
                          </td>
                          <td class="align-middle text-center"
                            v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}" 
                            role="button"
                            @click="exibeDetalhes(e)"
                          >
                            <small class="text-success" v-if="e.matricula_ativa">Ativa</small>       
                            <small class="text-danger" v-else>Inativa</small>
                          </td>
                          <td class="align-middle text-center"
                            v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                            role="button"
                            @click="exibeDetalhes(e)"  
                          >
                              <small>
                              {{ e.UsuarioFezaBaixa }}
                            </small>                               
                          </td>
                          <td class="align-middle text-center"
                            v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                          >
                            <a v-if="e.log_pagto_auditoria" role="button" title="LOG PAGAMENTO" @click.prevent="exibeModalVerLog(e.log_pagto_auditoria)" class="text-primary"><u>Ver</u></a>
                            <span v-else>-</span>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td
                            colspan="12"
                            class="text-center"
                          >
                            Nenhuma parcela encontrada
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-12 text-right mt-4">
                      <div class="d-inline">
                        <h6 class="font-weight-bold d-inline">Valor Total Matrículas: </h6>
                        <h5 class="d-inline"><b-badge variant="primary">R$ {{ formataPreco(totalMatriculaSelected) }}</b-badge></h5>
                      </div>
                      <div class="my-2">
                        <h6 class="font-weight-bold d-inline">Valor Total Matrículas Recebido: </h6>
                        <h5 class="d-inline"><b-badge variant="success">R$ {{ formataPreco(totalMatriculaPagaSelected) }}</b-badge></h5>
                      </div>
                  </div>
                  <div class="col-12 text-center mt-4">
                <Pagination
                  :page-size="20"
                  :items="fastMatriculasRel.filtro"
                  @changePage="fastMatriculasRel.paginacao = $event"
                />
                </div>
              </div>
            </div>
          </transition>
          <!-- /Conteúdo Principal Relatório -->

          <!-- Detalhes - Ações -->
          <transition name="slide-fade">
            <div v-if="fastNavegacao.exibirDetalhes">
              <DetalhesRecebivel 
                :id_plataforma="fastInvoiceFiltrosDetalhes.idPlataforma" 
                :id_invoice="fastInvoiceFiltrosDetalhes.idInvoice" 
                :parcela="fastInvoiceFiltrosDetalhes.parcela"
                tipo="detalhes"
              />
            </div>
          </transition>
          <!-- /Detalhes - Ações -->

          <div class="mt-5 mb-5">
            <a
              v-if="fastNavegacao.exibirRelatorio" 
              id="btn_voltar_modo_admin"
              class="btn btn-red-hollow"
              :href="
                '/plataforma/' + $route.params.id_plataforma + '/tesouraria' 
              "
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
            >
              <b-icon-arrow-return-left />
              Voltar
            </a>
            <a
              v-if="fastNavegacao.exibirDetalhes"
              id="btn_voltar_modo_admin"
              class="btn btn-red-hollow"
              :href="
                '/plataforma/' + $route.params.id_plataforma + '/tesouraria' 
              "
              @click.prevent="alteraFastNavegacao('exibirRelatorio')"
            >
              <b-icon-arrow-return-left />
              Voltar
            </a>
          </div>

        </div>

        <!--modals-->
        <modal
          name="modalVerLog"
          :scrollable="true"
          height="auto"
          :shift-y="0.3"
          :focus-trap="true"
          :adaptive="true"
        >
          <div class="row p-4">
            <div class="col-10 pl-4 pr-4">
              <h4>Log / Motivo</h4>
            </div>
            <div class="col-2 pl-4 pr-4 text-right">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalVerLog')"
              >
                <b-icon-arrow-return-left />
              </a>
            </div>
            <div class="col-12 p-4">
              <div class="row">
                <div class="col-12 mt-4 mb-4">

                  <p class="text-info">
                    {{ fastMatriculasRel.logRecebivel }}
                  </p>

                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12">
                  <button class="btn btn-secondary mr-2" @click="hideModal('modalVerLog'); fastLinkClipboard = false">
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </modal>

      </div>
    </div>
  </template>
  
  <script>
  import { settings } from "@/config";
  import methods from "@/methods";
  import Pagination from "../components/Pagination";
  import DetalhesRecebivel from "../components/DetalhesRecebivel.vue"
  import * as XLSX from 'xlsx';
  
  export default {
    name: "HomeInternoTesourariaRelatoriosMatriculas",
    components: {
      Pagination,
      DetalhesRecebivel
    },
    mixins: [methods],
    data: function () {
      return {
        modalWidthSm: this.isMobile() ? "80%" : "30%",
        modalWidthMd: this.isMobile() ? "90%" : "50%",
        modalWidthLg: this.isMobile() ? "100%" : "90%",
        
        fastLinkClipboard: false,
        fastNavegacao: {
          exibirRelatorio : true,
          exibirDetalhes : false,
        },
        fastMatriculasRel: {
          loading: true,
          filtro: [],
          paginacao: [],
          filtroEscolhido : {
            idPlataforma: this.$route.params.id_plataforma,
            nomeAluno: '',
            nomeResponsavel: '',
            idInvoice: 0,
            idTurma: 0,
            idMatricula: 0,
            idMatriculador: 0,
            dataMatriculaStart: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
            dataMatriculaEnd: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
            matriculaPaga: '',
            matriculaPendente: '',
            matriculaCancelada: ''
          },
          filtros : {
            plataformas: [],
            status: '',
            turmas: [],
            usuariosGerou : [],
            usuariosCancelou : [],
            usuarioGerou: '',
            usuarioCancelou: '',
            datalistId: 'suggestions-list-' + Math.floor(Math.random() * 1000000)
          },
          logRecebivel: '',
          gatewaysPagamento: [],
          orderBy: {
            data_matricula: 0, 
            turma: 0, 
            matricula: 0,
            invoice: 0, 
            matriculador: 0,
            inicio_estudos: 0,
            valor_matricula: 0,
            mensalidade: 0,
            data_pagamento: 0,
            status: 0, 
            aluno: 0, 
          },
          selectedItems: [],
          selectAll: false
        },
        fastInvoiceFiltrosDetalhes: {
          idPlataforma : '',
          idInvoice : '',
          parcela : '',
        },
        tableData : [],
      };
    },
    mounted: function () {
      if (this.$route.params.id_plataforma) {
        this.getFastDependecias(this.$route.params.id_plataforma, 'tesouraria')
          .then(() => {
            if (
              this.$store.state.fastPlataformaModulos.modulo_secretaria
            ) {            
              this.$store.state.fastCarregando = false;
              this.getFastHoraServidor()
              .then(() => {
                this.getPlataformasFilhas()
                this.getTurmas()
                this.getMatriculasRel(this.$route.params.id_plataforma)
              })
  
            } else {
              this.$store.state.fastCarregando = false;
              this.exibeToasty("Módulo não disponível para sua plataforma", "error");
            }
          })
          .catch((e) => {
            this.exibeToasty(e, "error");
            this.$store.state.fastCarregando = false;
          });
      }
    },
    computed: {
      totalMatriculaSelected() {
        return this.fastMatriculasRel.filtro.reduce((total, item) => {
          if (!this.fastMatriculasRel.selectedItems.length){
            return total + item.valor_matricula
          }
          if (this.fastMatriculasRel.selectedItems.includes(item.id_invoice + "-" + item.parcela)) {
            return total + item.valor_matricula
          } else {
            return total
          }
        }, 0)
      },
      totalMatriculaPagaSelected() {
        return this.fastMatriculasRel.filtro.reduce((total, item) => {
          if (!this.fastMatriculasRel.selectedItems.length){
            return item.pago ? (total + item.valor_matricula) : total
          }
          if (this.fastMatriculasRel.selectedItems.includes(item.id_invoice + "-" + item.parcela)) {
            return item.pago ? (total + item.valor_matricula) : total
          } else {
            return total
          }
        }, 0)
      },
    },
    watch: {
      'fastMatriculasRel.selectAll'(val) {
        if (val) {
          this.fastMatriculasRel.selectedItems = this.fastMatriculasRel.filtro.map((item) => item.id_invoice + "-" + item.parcela)
        } else {
          this.fastMatriculasRel.selectedItems = []
        }
      }
    },
    methods: {
      getPlataformasFilhas(){
      //Pega plataformas filhas para o filtro
      this.promiseGetFastApi("api/fast_plataforma/lista_filhas_novo", `id_plataforma=${this.fastMatriculasRel.filtroEscolhido.idPlataforma}`)
      .then((res) => {          
        if (res.Data.length) {
          this.fastMatriculasRel.filtros.plataformas = res.Data;
        } else{
          this.fastMatriculasRel.filtros.plataformas = this.$route.params.id_plataforma;
        }
      })
      .catch((e) => {
          console.log(e);
      });
      },
      getTurmas(){
        //Pega turmas para o filtro
        this.promiseGetFastApi("api/fast_secretaria_turma/lista", `id_plataforma=${this.fastMatriculasRel.filtroEscolhido.idPlataforma}&id_turma=0`)
        
        .then((res) => {      
          if (res.length) {
            this.fastMatriculasRel.filtros.turmas = res;
          }else{
            this.fastMatriculasRel.filtros.turmas = [];
          }
        })
        .catch((e) => {
            console.log(e);
        });
      },
      async getMatriculasRel(){       
        this.fastMatriculasRel.loading = true

        let plataforma;
        let todasPlataformasFilhas;

        todasPlataformasFilhas = this.fastMatriculasRel.filtroEscolhido.idPlataforma === 'Todas';
        plataforma = todasPlataformasFilhas ? this.$route.params.id_plataforma : this.fastMatriculasRel.filtroEscolhido.idPlataforma;

        this.promiseGetFastApi("api/fast_tesouraria_invoice_parcela/lista_matriculas", `IdPlataforma=${plataforma}&IdTurma=${this.fastMatriculasRel.filtroEscolhido.idTurma}&NomeAluno=${this.fastMatriculasRel.filtroEscolhido.nomeAluno}&NomeResponsavel=${this.fastMatriculasRel.filtroEscolhido.nomeResponsavel}&IdInvoice=${this.fastMatriculasRel.filtroEscolhido.idInvoice}&MatriculaAluno=${this.fastMatriculasRel.filtroEscolhido.idMatricula}&DataMatriculaStart=${this.fastMatriculasRel.filtroEscolhido.dataMatriculaStart}&DataMatriculaEnd=${this.fastMatriculasRel.filtroEscolhido.dataMatriculaEnd}&IdMatriculador=${this.fastMatriculasRel.filtroEscolhido.idMatriculador}&MatriculaPaga=${this.fastMatriculasRel.filtroEscolhido.matriculaPaga}&MatriculaPendente=${this.fastMatriculasRel.filtroEscolhido.matriculaPendente}&MatriculaCancelada=${this.fastMatriculasRel.filtroEscolhido.matriculaCancelada}`)

        .then(res => {
          console.log("getMatriculasRel", res)
          if (res.length) {
            this.fastMatriculasRel.filtro = todasPlataformasFilhas ? res : res.filter(item => item.id_plataforma == this.fastMatriculasRel.filtroEscolhido.idPlataforma);
          }
          else {
            this.fastMatriculasRel.filtro = []
          }
  
          this.fastMatriculasRel.loading = false
        }).catch(e => {
          console.log(e)
          this.exibeToasty(e, "error")
          this.fastMatriculasRel.loading = false
        })
      },
      alteraFiltroStatus(){
        if(this.fastMatriculasRel.filtros.status == 'P'){
          this.fastMatriculasRel.filtroEscolhido.matriculaCancelada = '';
          this.fastMatriculasRel.filtroEscolhido.matriculaPendente = '';
          this.fastMatriculasRel.filtroEscolhido.matriculaPaga = true;
        }
        if(this.fastMatriculasRel.filtros.status == 'A'){
          this.fastMatriculasRel.filtroEscolhido.matriculaCancelada = '';
          this.fastMatriculasRel.filtroEscolhido.matriculaPaga = '';
          this.fastMatriculasRel.filtroEscolhido.matriculaPendente = true;
        }
        if(this.fastMatriculasRel.filtros.status == 'C'){
          this.fastMatriculasRel.filtroEscolhido.matriculaPendente = '';
          this.fastMatriculasRel.filtroEscolhido.matriculaPaga = '';
          this.fastMatriculasRel.filtroEscolhido.matriculaCancelada = true;
        }
        if(!this.fastMatriculasRel.filtros.status){
          this.fastMatriculasRel.filtroEscolhido.matriculaPendente = '';
          this.fastMatriculasRel.filtroEscolhido.matriculaPaga = '';
          this.fastMatriculasRel.filtroEscolhido.matriculaCancelada = '';
        }
      },
      filtraPessoaSugestao(filtro, listagem){
      if (this.fastMatriculasRel.filtros[filtro].length >= 3) {
        setTimeout(() => {
          this.promiseGetFastApi("api/fast_pessoa/lista_paginado", `PageIndex=1&PageSize=100&id_plataforma=${this.fastMatriculasRel.filtroEscolhido.idPlataforma}&nome_pessoa=${this.fastMatriculasRel.filtros[filtro]}&matriculador=true`)
          .then((res) => {          
            if (res.Data.length) {
              this.fastMatriculasRel.filtros[listagem] = res.Data;
              }
            })
            .catch((e) => {
                console.log(e);
            });
          }, 1000)
          } else {
            this.fastMatriculasRel.filtros.usuariosGerou = []
        }
      },
      selecionaPessoaSugerida(usuario) {
          this.fastMatriculasRel.filtros.usuariosGerou = [];
          if(usuario == ''){
            this.fastMatriculasRel.filtroEscolhido.idUsuarioGerou = '';
            this.fastMatriculasRel.filtros.usuarioGerou = '';
          }else{
            this.fastMatriculasRel.filtros.usuarioGerou = usuario.nome_razao;
            this.fastMatriculasRel.filtroEscolhido.idMatriculador = usuario.id_usuario;
          }
      },
      limpaFiltros(){
        this.fastMatriculasRel.filtroEscolhido.idPlataforma = this.$route.params.id_plataforma
        this.fastMatriculasRel.filtroEscolhido.dataMatriculaStart = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10)
        this.fastMatriculasRel.filtroEscolhido.dataMatriculaEnd = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10)
        this.fastMatriculasRel.filtroEscolhido.nomeAluno = ''
        this.fastMatriculasRel.filtroEscolhido.nomeResponsavel = ''
        this.fastMatriculasRel.filtroEscolhido.idInvoice = ''
        this.fastMatriculasRel.filtroEscolhido.idTurma = ''
        this.fastMatriculasRel.filtroEscolhido.idMatriculador = ''
        this.fastMatriculasRel.filtroEscolhido.idMatricula = ''
        this.fastMatriculasRel.filtroEscolhido.matriculaPaga = ''
        this.fastMatriculasRel.filtroEscolhido.matriculaCancelada = ''
        this.fastMatriculasRel.filtroEscolhido.matriculaPendente = ''
        this.fastMatriculasRel.filtros.status = ''
        this.fastMatriculasRel.filtros.usuarioGerou = ''
        this.fastMatriculasRel.filtros.usuarioCancelou =  ''
        this.fastMatriculasRel.filtros.usuariosGerou = []
        this.fastMatriculasRel.filtros.usuariosCancelou = []

        this.getMatriculasRel();
      },
      calculaDiasAtraso(dataVenc){
        const dataAtual = new Date()
        const dataCalc = new Date(dataVenc)

        const difMs = dataAtual - dataCalc
        const difDias = difMs / 86400000 - 1

        return difDias.toFixed();
      },
      exportaPlanilha(){
        const table = document.querySelector('#relatorio');
        const tableRows = table.querySelectorAll('tr');
        const tableData = [];

        for (let i = 0; i < tableRows.length; i++) {
          const tableCells = tableRows[i].querySelectorAll('td');
          const rowData = [];

          for (let j = 0; j < tableCells.length; j++) {
            rowData.push(tableCells[j].innerText);
          }

          tableData.push(rowData);
        }

        tableData[0] = ['Data Matrícula', 'Turma', 'Matrícula', 'Invoice', 'Matriculador', 'Início Estudo', 'Valor Matrícula', 'Mensalidade', 'Data Pagamento', 'Status', 'Aluno', 'Log'];

        const worksheet = XLSX.utils.aoa_to_sheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'FastEAD - relatorio-matriculas.xlsx');
      },
      exibeModalVerLog(e){
        this.fastMatriculasRel.logRecebivel = e
        this.showModal('modalVerLog')
      },
      exibeDetalhes(e){
        this.fastInvoiceFiltrosDetalhes.idPlataforma = Number(this.fastMatriculasRel.filtroEscolhido.idPlataforma)
        this.fastInvoiceFiltrosDetalhes.idInvoice = e.id_invoice
        this.fastInvoiceFiltrosDetalhes.parcela = 1

        this.alteraFastNavegacao('exibirDetalhes')
      },
      copyURLBoleto(link) {
      const el = document.createElement('textarea');  
      el.value = link;                                 
      el.setAttribute('readonly', '');                
      el.style.position = 'absolute';                     
      el.style.left = '-9999px';                      
      document.body.appendChild(el);                  
      const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;                                    
      el.select();                                    
      document.execCommand('copy');                   
      document.body.removeChild(el);                  
      if (selected) {                                 
        document.getSelection().removeAllRanges();    
        document.getSelection().addRange(selected);   
      }
      },
      orderByColumn(coluna) {
        Object.keys(this.fastMatriculasRel.orderBy).forEach(key => {
          if (key !== coluna) {
            this.fastMatriculasRel.orderBy[key] = 0;
          }
        });
        // Atualiza a ordem de classificação para a coluna clicada
        this.fastMatriculasRel.orderBy[coluna] = this.fastMatriculasRel.orderBy[coluna] === 1 ? -1 : 1;

        // Classifica a tabela de acordo com as colunas ordenadas e a ordem de classificação
        this.fastMatriculasRel.filtro.sort((a, b) => {
          const order = this.fastMatriculasRel.orderBy[coluna];
          
          if (coluna === 'data_matricula') {
            return (a.data_matricula > b.data_matricula ? 1 : -1) * order; 
          } else if (coluna === 'turma') {
            return a.sigla_turma.localeCompare(b.sigla_turma) * order;
          } else if (coluna === 'matricula') {
            return (a.id_matricula > b.id_matricula ? 1 : -1) * order; 
          } else if (coluna === 'invoice') {
            return (a.id_invoice > b.id_invoice ? 1 : -1) * order; 
          } else if (coluna === 'matriculador') {
            return a.UsuarioFezaBaixa.localeCompare(b.UsuarioFezaBaixa) * order;
          } else if (coluna === 'inicio_estudos') {
            return (a.data_matricula > b.data_matricula ? 1 : -1) * order; 
          } else if (coluna === 'valor_matricula') {
            return (a.valor_matricula - b.valor_matricula) * order;
          } else if (coluna === 'mensalidade') {
            return (a.valor_parcela - b.valor_parcela) * order;
          } else if (coluna === 'data_pagamento') {
            function ordenarPorData(a, b, order) {
              // Verifica se ambos os valores são nulos ou iguais a "Sem data"
              if ((!a || a === 'Sem data') && (!b || b === 'Sem data')) {
                return 0;
              }

              // Verifica se apenas um dos valores é nulo ou "Sem data" e coloca por último
              if (!a || a === 'Sem data') {
                return 1 * order;
              }

              if (!b || b === 'Sem data') {
                return -1 * order;
              }

              // Ordena as datas restantes normalmente
              const dataA = new Date(a);
              const dataB = new Date(b);

              return (dataA > dataB ? 1 : -1) * order;
            }

            return ordenarPorData(a.data_pagamento, b.data_pagamento, order);
          } else if (coluna === 'data_vencimento_mensalidade') {
            function ordenarPorData(a, b, order) {
              // Verifica se ambos os valores são nulos ou iguais a "Sem data"
              if ((!a || a === 'Sem data') && (!b || b === 'Sem data')) {
                return 0;
              }

              // Verifica se apenas um dos valores é nulo ou "Sem data" e coloca por último
              if (!a || a === 'Sem data') {
                return 1 * order;
              }

              if (!b || b === 'Sem data') {
                return -1 * order;
              }

              // Ordena as datas restantes normalmente
              const dataA = new Date(a);
              const dataB = new Date(b);

              return (dataA > dataB ? 1 : -1) * order;
            }

            return ordenarPorData(a.data_vencimento_mensalidade, b.data_vencimento_mensalidade, order);
          } else if (coluna === 'status') {
              if (a.cancelado) return -1 * order;
              if (b.cancelado) return 1 * order;
              if (a.valor_pago && !b.valor_pago) return -1 * order;
              if (!a.valor_pago && b.valor_pago) return 1 * order;
              return 0;
          } else if (coluna === 'aluno') {
            return a.nome_aluno.localeCompare(b.nome_aluno) * order;
          }  else {
              return 0;
          }
        });
      },
      validaDataFiltro(inicio, fim, infErro){
        if(inicio === '' || fim === ''){
          return true
        } else if(inicio > fim){
          if (infErro) this.exibeToasty('A data inicial não pode ser maior que a data final', 'error');
          return false
        }
        return true;
      },
      criaTooltip(e){

        return `<div class="text-left">
                  Aluno: ${e.nome_aluno}<br>
                  Resp.: ${e.nome_responsavel}<br>
                  Data Matrícula: ${this.formataDataT(e.data_matricula)}<br>
                  Valor Matrícula: R$ ${this.formataPreco(e.valor_matricula)}<br>
                  Mensalidade: R$ ${this.formataPreco(e.valor_parcela)}
                </div>`;
      }
    },
  };
  </script>
  
  <style scope>
  .container{
    max-width: 1280px;
  }
  .sugestao-lista {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #d8d8d8;
    border-top: none;
    padding: 0;
    margin: 0;
    z-index: 999;
  }
  .sugestao-item {
    list-style-type: none;
    padding: 3px 6px;
    cursor: pointer;
    color: rgb(95, 95, 95);
  }
  .sugestao-item:hover {
    transition: 0.3s;
    background-color: #e8e8e8;
  }
  .date-picker-filtro  {
    text-align: center;
  }
  .btn-custom-primary{
    background-color: #007bff!important;
    color: #fff!important;
  }
  .btn-custom-outline-primary{
    background-color: #fff!important;
    color: #007bff!important;
    border:1px solid #007bff!important;
  }

  </style>
  